<template>
  <TransitionGroup
    class="chat-container"
    tag="div"
    :name="animationName"
    ref="container"
    :class="{
      'chat-container-vertical': display === 'vertical',
      'chat-container-horizontal': display === 'horizontal',
      'chat-container-random': display === 'random',
      'chat-container-left': alignment === 'left',
      'chat-container-right': alignment === 'right',
    }"
  >
    <div
      v-for="message in activeChats"
      :key="message.id"
      class="message-card"
      :style="{
        color: fontColor,
        fontFamily: `${fontFamily}, Arial, sans-serif`,
        '--top': ((message.position && message.position.y) || 0) + '%',
        '--left': ((message.position && message.position.x) || 0) + '%',
        border: `${borderWidth}px solid ${borderColor}`,
        borderRadius: `${borderRadius}em`,
        marginTop: userNamePosition === 'top' ? '1em' : '',
        fontSize: fontSize + 'px',
      }"
      :class="{
        'message-card-left': alignment === 'left',
        'message-card-right': alignment === 'right',
        'message-card-follow': [
          'follow',
          'uplift',
          'meteor-shower',
          'subscription',
        ].includes(message.type),
      }"
    >
      <span
        v-if="message.type === 'chat'"
        class="message-username"
        :class="{
          'message-username-inline': userNamePosition === 'inline',
          'message-username-top': userNamePosition === 'top',
        }"
        :style="
          userNamePosition === 'top'
            ? {
                backgroundColor: usernameBackgroundColor,
                border: `${borderWidth}px solid ${usernameBorderColor}`,
                borderRadius: `${borderRadius}em`,
                color: message.message.color,
                fontWeight: 'bold',
              }
            : {
                color: message.color,
                fontWeight: 'bold',
              }
        "
      >
        <span
          v-if="message.message.badges && message.message.badges"
          class="message-badges"
        >
          <img
            v-for="badge in message.message.badges"
            :key="badge.type"
            :src="getBadge(badge)"
            style="display: inline-block; height: 1em; vertical-align: middle"
          />
        </span>
        {{ message.chatter_user_name }}
        <span v-if="userNamePosition === 'inline'">:</span>
      </span>
      <span
        v-else-if="
          [
            'new_follower',
            'subscription_renewed',
            'uplifting_chat_sent',
            'shower_received',
          ].includes(message.type)
        "
        class="message-username message-username-top message-username-follow"
        :style="{
          backgroundColor: usernameBackgroundColor,
          border: `${borderWidth}px solid ${usernameBorderColor}`,
          borderRadius: `${borderRadius}em`,
          fontWeight: 'bold',
          color: usernameBorderColor,
        }"
      >
        <span v-if="message.type === 'new_follower'">New Follow </span>
        <span v-if="message.type === 'uplifting_chat_sent'">New Uplift </span>
        <span v-if="message.type === 'shower_received'">New Meteor Shower</span>
        <span v-if="message.type === 'subscription_renewed'"
          >New Subscription
        </span>
      </span>
      <template v-if="message.type === 'new_follower'">
        {{ message.data.displayName }} followed!
      </template>
      <template v-else-if="message.type === 'uplifting_chat_sent'">
        {{ message.data.sender.displayName }} uplifted with
        {{ message.data.amount.formatted }}!
      </template>
      <template v-else-if="message.type === 'subscription_renewed'">
        {{ message.data.subscriber.displayName }} subscribed (tier
        {{ message.data.tier }})!
      </template>
      <template v-else-if="message.type === 'shower_received'">
        New Meteor Shower from {{ message.data.sender.displayName }} with
        {{ message.data.audienceSize }} viewers!
      </template>
      <template v-else-if="message.type === 'chat'">
        <template v-for="(node, i) in message.message.fragments">
          <span
            :key="'text-' + i"
            v-if="node.type === 'text'"
            class="chat-node chat-text"
          >
            {{ node.text }}
          </span>
          <span
            :key="'emoji-' + i"
            v-else-if="node.type === 'emote'"
            class="chat-node chat-emoji"
          >
            <img
              :src="`https://static-cdn.jtvnw.net/emoticons/v2/${node.emote.id}/default/dark/2.0`"
              style="display: inline-block; height: 1em"
            />
          </span>
          <span
            :key="'mention-' + i"
            v-else-if="node.type === 'mention'"
            class="chat-node chat-mention"
          >
            @{{ node.mention.user_name }}
          </span>
          <span
            :key="'cheer-' + i"
            v-else-if="node.type === 'cheer'"
            class="chat-node chat-cheer"
            :style="{
              color: getCheermote(node.cheermote.prefix, node.cheermote.tier)
                ? getCheermoteColor(node.cheermote.prefix, node.cheermote.tier)
                : '',
            }"
          >
            <img
              :src="getCheermote(node.cheermote.prefix, node.cheermote.tier)"
            />
            {{ node.cheermote.bits }}
          </span>
        </template>
      </template>
    </div>
  </TransitionGroup>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watchEffect,
  nextTick,
  watch,
} from "vue";
import { CUSTOM_EVENTS } from "../../../api/events";
import WebFont from "webfontloader";
import cheerEmotes from "../../../api/twitch/cheers.js";

// MOD BADGE https://vstream.com/build/_assets/moderator-RXLWJZX3.png

function hexToRgb(hex) {
  try {
    return hex
      ? {
          r: parseInt(hex.substring(1, 3), 16),
          g: parseInt(hex.substring(3, 5), 16),
          b: parseInt(hex.substring(5, 7), 16),
          a: parseInt(hex.substring(7, 9) || "00", 16) / 255,
        }
      : null;
  } catch (e) {
    return null;
  }
}

export default {
  name: "ChatWidget",
  props: {
    settings: {
      required: true,
    },
    user: {
      required: true,
    },
    cache: {
      type: Object,
      required: false,
    },
  },
  setup(props, ctx) {
    const messages = ref([]);
    const container = ref(null);

    const borderColor = computed(() => {
      return props.settings.borderColor || "#00000000";
    });
    const borderWidth = computed(() => {
      if (!props.settings.hasOwnProperty("borderWidth")) {
        return 1;
      }
      return props.settings.borderWidth || 0;
    });
    const borderRadius = computed(() => {
      if (!props.settings.hasOwnProperty("borderRadius")) {
        return 1;
      }
      return props.settings.borderRadius || 0;
    });

    const display = computed(() => {
      return props.settings.display || "random";
    });

    const userNamePosition = computed(() => {
      return props.settings.userNamePosition || "inline";
    });

    const alignment = computed(() => {
      return props.settings.alignment || "left";
    });

    const fontSize = computed(() => {
      return props.settings.fontSize || 16;
    });

    const usernameBackgroundColor = computed(() => {
      return (
        props.settings.usernameBackgroundColor ||
        props.settings.backgroundColor ||
        "#A1A1A1"
      );
    });

    const usernameBorderColor = computed(() => {
      return (
        props.settings.usernameBorderColor ||
        props.settings.borderColor ||
        "#00000000"
      );
    });

    const messageDuration = computed(() => {
      if (
        props.settings.hasOwnProperty("messageDuration") &&
        props.settings.messageDuration
      ) {
        return props.settings.messageDuration;
      }
      if (display.value === "random") {
        return 10000;
      }
      return null;
    });

    const events = computed(() => {
      return props.settings.events || [];
    });

    function getCheermote(prefix, tier) {
      const cheermotes = props.cache.cheermotes || [];

      const cheerEmote = cheermotes.find((cheer) => cheer.prefix === prefix);
      if (!cheerEmote) {
        console.warn("Cheermote not found", prefix, tier);
        return null;
      }

      const cheerTier = cheerEmote.tiers.find((t) => t.id === tier.toString());
      if (!cheerTier) {
        console.warn("Cheer tier not found", prefix, tier);
        return null;
      }

      return cheerTier.images.dark.animated[2];
    }
    function getCheermoteColor(prefix, tier) {
      const cheermotes = props.cache.cheermotes || [];

      const cheerEmote = cheermotes.find((cheer) => cheer.prefix === prefix);
      if (!cheerEmote) {
        console.warn("Cheermote not found", prefix, tier);
        return null;
      }

      const cheerTier = cheerEmote.tiers.find((t) => t.id === tier.toString());
      if (!cheerTier) {
        console.warn("Cheer tier not found", prefix, tier);
        return null;
      }

      return cheerTier.color;
    }

    function getBadgeFromSetId(setId, version, size = 2) {
      const set = props.cache.badges.find((set) => set.set_id === setId);
      if (!set) {
        return null;
      }
      const _version = set.versions.find((v) => version === v.id);

      if (!_version) {
        return null;
      }
      return _version[`image_url_${size}x`];
    }

    function onChatMessage(message) {
      if (!message.detail) {
        return;
      }
      if (message.detail.type === "channel.chat.message") {
        //prevent duplicates
        if (messages.value.find((m) => m.id === message.detail.id)) {
          return;
        }
        // only allow 50 message in chat
        messages.value.push({
          type: "chat",
          id: message.detail.data.message_id,
          ...message.detail.data,
          //color: sanitizeRGB(message.detail.data.color),
          position: {
            x: Math.floor(Math.random() * 100),
            y: Math.floor(Math.random() * 100),
          },
        });
        if (messageDuration.value) {
          setTimeout(() => {
            messages.value = messages.value.filter(
              (m) => m.id !== message.detail.data.message_id,
            );
          }, messageDuration.value);
        }
        if (messages.value.length > 50) {
          messages.value.shift();
        }
      }
      /*
      if (message.detail.type === "chat_created") {
        //prevent duplicates
        if (messages.value.find((m) => m.id === message.detail.id)) {
          return;
        }
        // only allow 50 message in chat
        messages.value.push({
          type: "chat",
          ...message.detail.data,
          color: sanitizeRGB(message.detail.data.color),
          position: {
            x: Math.floor(Math.random() * 100),
            y: Math.floor(Math.random() * 100),
          },
        });
        if (messageDuration.value) {
          setTimeout(() => {
            messages.value = messages.value.filter(
              (m) => m.id !== message.detail.data.id,
            );
          }, messageDuration.value);
        }
        if (messages.value.length > 50) {
          messages.value.shift();
        }
      } else
        */
      if (message.detail.type === "chat_deleted") {
        messages.value = messages.value.filter(
          (m) => m.id !== message.detail.data.id,
        );
      } else if (message.detail.type === "chatter_chats_deleted") {
        messages.value = messages.value.filter(
          (m) => m.chatter.userID !== message.detail.data.chatterID,
        );
      } else if (message.detail.type === "new_follower") {
        if (events.value.includes("follow")) {
          let id = Date.now() + Math.random();
          messages.value.push({
            id: id,
            ...message.detail,
            type: "new_follower",
            position: {
              x: Math.floor(Math.random() * 100),
              y: Math.floor(Math.random() * 100),
            },
          });
          if (messageDuration.value) {
            setTimeout(() => {
              messages.value = messages.value.filter((m) => m.id !== id);
            }, messageDuration.value);
          }
        }
      } else if (message.detail.type === "uplifting_chat_sent") {
        if (events.value.includes("uplift")) {
          let id = Date.now() + Math.random();

          messages.value.push({
            ...message.detail,
            id: id,
            type: "uplifting_chat_sent",
            position: {
              x: Math.floor(Math.random() * 100),
              y: Math.floor(Math.random() * 100),
            },
          });
          if (messageDuration.value) {
            setTimeout(() => {
              messages.value = messages.value.filter((m) => m.id !== id);
            }, messageDuration.value);
          }
        }
      } else if (message.detail.type === "subscription_renewed") {
        if (events.value.includes("subscription")) {
          let id = Date.now() + Math.random();

          messages.value.push({
            id: id,
            ...message.detail,
            type: "subscription_renewed",
            position: {
              x: Math.floor(Math.random() * 100),
              y: Math.floor(Math.random() * 100),
            },
          });
          if (messageDuration.value) {
            setTimeout(() => {
              messages.value = messages.value.filter((m) => m.id !== id);
            }, messageDuration.value);
          }
        }
      } else if (message.detail.type === "shower_received") {
        if (events.value.includes("meteor-shower")) {
          let id = Date.now() + Math.random();

          messages.value.push({
            id: id,

            ...message.detail,
            type: "shower_received",
            position: {
              x: Math.floor(Math.random() * 100),
              y: Math.floor(Math.random() * 100),
            },
          });
          if (messageDuration.value) {
            setTimeout(() => {
              messages.value = messages.value.filter((m) => m.id !== id);
            }, messageDuration.value);
          }
        }
      }
    }
    function loadChatMessagesFromCache() {
      const messageCache = [
        ...(
          (props &&
            props.cache &&
            props.cache.chat &&
            props.cache.chat.messages) ||
          []
        ).map((m) => {
          const data = {
            ...m,
            position: {
              x: Math.floor(Math.random() * 100),
              y: Math.floor(Math.random() * 100),
            },
          };

          if (data.chatterBadges && data.chatterBadges.length) {
            data.chatterBadges = data.chatterBadges.map((b) => {
              return {
                ...b,
                type: b.__typename,
              };
            });
          }
          return data;
        }),
      ];
      const deletedMessages = messageCache.filter(
        (m) => m.__typename === "DeletedChat",
      );
      const activeMessages = messageCache.filter(
        (m) =>
          m.__typename === "ActiveChat" &&
          !deletedMessages.find((message) => message.id === m.id),
      );
      const types = [];

      messages.value = activeMessages.map((message) => {
        if (!types.includes(message.__typename)) {
          types.push(message.__typename);
        }
        return {
          ...message,
          nodes:
            (message.nodes &&
              message.nodes.map((n) => {
                return { ...n, type: n.__typename };
              })) ||
            [],
        };
      });
    }

    const fontFamily = computed(() => {
      return props.settings.fontFamily || "Inter";
    });

    watch(display, (newVal) => {
      if (newVal) {
        messages.value = [];
      }
    });
    onMounted(() => {
      WebFont.load({
        google: {
          families: [fontFamily.value],
        },
      });
      if (display.value !== "random" && !messageDuration.value) {
        loadChatMessagesFromCache();
      }

      window.addEventListener(CUSTOM_EVENTS.CHAT_CREATED_EVENT, onChatMessage);
      window.addEventListener(
        CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT,
        onChatMessage,
      );
      /*
      window.addEventListener(
        CUSTOM_EVENTS.UPLIFTING_CHAT_CREATED_EVENT,
        onChatMessage
      );
      window.addEventListener(
        CUSTOM_EVENTS.SUBSCRIPTION_ACTIVATED_EVENT,
        onChatMessage
      );
      window.addEventListener(
        CUSTOM_EVENTS.METEOR_SHOWER_RECEIVED_EVENT,
        onChatMessage
      );*/
      nextTick(() => {
        if (container.value) {
          const rgb = hexToRgb(props.settings.backgroundColor);
          container.value.$el.style.setProperty(
            "--bg",
            `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`,
          );
        }
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener(
        CUSTOM_EVENTS.CHAT_CREATED_EVENT,
        onChatMessage,
      );
      window.removeEventListener(
        CUSTOM_EVENTS.UPLIFTING_CHAT_CREATED_EVENT,
        onChatMessage,
      );
      window.removeEventListener(
        CUSTOM_EVENTS.SUBSCRIPTION_ACTIVATED_EVENT,
        onChatMessage,
      );
      window.removeEventListener(
        CUSTOM_EVENTS.METEOR_SHOWER_RECEIVED_EVENT,
        onChatMessage,
      );
    });
    watchEffect(() => {
      if (container.value) {
        const rgb = hexToRgb(props.settings.backgroundColor);
        container.value.$el.style.setProperty(
          "--bg",
          `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`,
        );
      }
    });

    const showAnimation = computed(() => {
      return props.settings.showAnimations;
    });

    const animationName = computed(() => {
      if (!showAnimation.value) {
        return "";
      }
      if (display.value === "horizontal" && alignment.value === "right") {
        return "chat-transition-horizontal-right";
      }
      if (display.value === "random") {
        return "chat-transition-random";
      }
      return "chat-transition";
    });

    const activeChats = computed(() => {
      // TODO handle deleted chats
      const active = messages.value.filter((m) => m.type === "ActiveChat");

      const deleted = messages.value.filter((m) => m.type === "DeletedChat");

      active.forEach((a) => {
        deleted.forEach((d) => {
          if (a.id === d.id) {
            console.log("found deleted chat", a, d.id);
          }
        });
      });
      return messages.value;
    });

    const cacheCreateTime = computed(() => {
      return props.cache && props.cache.chat.connectedAt;
    });
    watch(cacheCreateTime, () => {
      loadChatMessagesFromCache();
    });

    const fontColor = computed(() => {
      return props.settings.fontColor || "ffffff";
    });

    function getBadge(badge) {
      return getBadgeFromSetId(badge.set_id, badge.id, 2);
    }

    function sanitizeRGB(color) {
      let c = color.replace("#", "");
      if (c.length === 3) {
        c = c
          .split("")
          .map((v) => v + v)
          .join("");
      }
      if (c.length === 5) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]].join("");
      }
      if (c.length === 6) {
        // do nothing
      } else {
        return "#000000";
      }
      return "#" + c;
    }

    return {
      display,
      container,
      messages,
      showAnimation,
      animationName,
      activeChats,
      fontColor,
      fontFamily,
      borderWidth,
      borderRadius,
      borderColor,
      userNamePosition,
      alignment,
      fontSize,
      usernameBackgroundColor,
      usernameBorderColor,
      sanitizeRGB,
      getBadge,
      getCheermote,
      getCheermoteColor,
    };
  },
};
</script>

<style scoped lang="scss">
.chat-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &.chat-container-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
    flex-wrap: nowrap;
    .message-card {
      padding: 0.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      & + .message-card {
        margin-left: 0.25em;
      }

      .chat-emoji {
        display: inline-flex;
        width: 1em !important;
        height: 1em !important;

        img {
          height: 1em !important;
          display: inline-block;
          width: 1em !important;
        }
      }
    }
    &.chat-container-right {
      flex-direction: row-reverse;
      .message-username-top {
        left: unset;
        right: 0.5em;
      }
    }
  }
  &.chat-container-random {
    height: 100%;
    .message-card {
      position: absolute;
      max-width: 30ch;
      width: max-content;
      height: max-content;
      top: var(--top, 0);
      left: var(--left, 0);
      transform: translate(calc(-1 * var(--left, 0)), calc(-1 * var(--top, 0)));
    }
  }
  &.chat-container-vertical {
    display: inline-flex;
    flex-direction: column;
  }
}
.message-card {
  padding: 0.75em 0.5em;
  border-radius: 0.25em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  color: white;
  background-color: var(--bg, rgba(100, 100, 100, 0.5));
  position: relative;
  display: inline-block;
  width: fit-content;
  &.message-card-follow {
    display: block;
    width: calc(100% - 4rem);
    margin-right: 2rem;
    margin-left: 2rem;
    text-align: center;
    .message-username {
      position: absolute !important;
      left: 50% !important;
      bottom: calc(100% - 0.75em) !important;
      transform: translate(-50%, 0) !important;
      white-space: nowrap;
    }
  }

  .message-username {
    z-index: 1;
    vertical-align: center;
    white-space: nowrap;
    &.message-username-top {
      position: absolute;
      bottom: calc(100% - 0.75em);
      left: 0.5em;
      z-index: 10;
      background-color: var(--bg, rgba(100, 100, 100, 0.5));
      padding: 0.25em 0.5em;
      font-size: 0.8em;
      line-height: 1;
      display: flex;
      align-items: center;
      .message-badges {
        img {
          margin-right: 0.25em !important;
          vertical-align: unset !important;
        }
      }
    }
  }
  &.message-card-right {
    align-self: end;
    .message-username-top {
      left: unset;
      right: 0.5em;
    }
  }
}

.chat-node {
  font-size: 1em;
  &:not(:first-child) {
    margin-left: 0.125em;
  }
  &:not(:last-child) + .chat-emoji {
    margin-right: 0em;
  }
  &:not(:last-child).chat-emoji + .chat-emoji {
    margin-left: 0.125em !important;
  }
}

.chat-emoji {
  font-family:
    "Noto Color Emoji",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Android Emoji",
    EmojiSymbols,
    EmojiOne Mozilla,
    Twemoji Mozilla,
    Segoe UI Symbol,
    "Noto Color Emoji Compat",
    emoji,
    Ellipsis,
    ShoraiSans,
    ShoraiSans Fallback,
    system-ui,
    sans-serif;

  font-weight: 400;
  font-size: 1.5em;
  vertical-align: middle;
  display: inline;
  margin-left: 0 !important;
}

.chat-cheer {
  white-space: nowrap;
  img {
    height: 1em;
    vertical-align: middle;
  }
  font-weight: bold;
}

.message-badges {
  display: inline-flex;
  img {
    vertical-align: center;
  }
}
</style>

<style>
.chat-transition-move,
.chat-transition-horizontal-right-move {
  /* apply transition to moving elements */
  transition: all 0.3s ease-in-out;
}
.chat-transition-enter-active,
.chat-transition-horizontal-right-enter-active,
.chat-transition-random-enter-active,
.chat-transition-leave-active,
.chat-transition-horizontal-right-leave-active,
.chat-transition-random-leave-active {
  transition: all 0.5s ease-in-out;
}

.chat-transition-enter-from,
.chat-transition-random-enter-from,
.chat-transition-leave-to,
.chat-transition-random-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.chat-transition-horizontal-right-enter-from,
.chat-transition-horizontal-right-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.chat-transition-leave-active,
.chat-transition-horizontal-right-leave-active {
  position: absolute;
}
</style>
