<template>
  <div
    ref="emoteWall"
    style="position: relative"
    :style="containerStyle"
    class="emote-wall"
  >
    <div
      v-for="emote in emotes"
      :key="emote.id"
      class="emoji"
      :style="{
        top: emote.y + '%',
        left: emote.x + '%',
        width: emote.width + '%',
        height: emote.height + '%',
        zIndex: 1000,
        '--xStart': emote.x + '%',
        '--xEnd': emote.xEnd + '%',
        '--yStart': emote.y + '%',
        '--yEnd': emote.yEnd + '%',
        '--duration': emote.duration + 'ms',
        '--rotationStart': emote.rotationStart + 'deg',
        '--rotationEnd': emote.rotationEnd + 'deg',
        '--scaleStart': emote.scaleStart,
        '--scaleEnd': emote.scaleEnd,
        '--opacityRatio': opacity,
      }"
    >
      <img
        :src="`https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/default/dark/2.0`"
        style="display: inline-block"
      />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { CUSTOM_EVENTS } from "../../../api/events";

function generateRandomID() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function randomFloatFromInterval(min, max) {
  return Math.random() * (max - min) + min;
}

export default {
  name: "EmoteWallWidget",
  props: {
    settings: {
      required: true,
    },
    user: {
      required: true,
    },
    cache: {
      type: Object,
      required: false,
    },
  },
  setup(props, ctx) {
    const emotes = ref([]);

    const emoteWall = ref(null);

    const opacity = computed(() => {
      if (props.settings.hasOwnProperty("opacity")) {
        return props.settings.opacity / 100;
      }
      return 0.5;
    });
    const containerStyle = computed(() => {
      return {
        fontSize: (props.settings.emoteSize / 50) * 16 + "px",
      };
    });

    function onChatMessage(message) {
      if (message.detail.data.message.fragments) {
        message.detail.data.message.fragments.forEach((node) => {
          if (node.type === "emote") {
            const duration = randomIntFromInterval(5000, 7500);
            const id = generateRandomID();
            const x = randomIntFromInterval(0, 100);
            const y = randomIntFromInterval(0, 100);
            const rotationStart = randomIntFromInterval(-60, 60);
            const scaleStart = randomFloatFromInterval(0.75, 1);

            emotes.value.push({
              emote: node,
              x,
              y,
              xEnd: x + randomIntFromInterval(-10, 10),
              yEnd: y + randomIntFromInterval(-10, 10),
              rotationStart,
              rotationEnd: rotationStart + randomIntFromInterval(-60, 60),
              scaleStart: randomFloatFromInterval(0.75, 1),
              scaleEnd: scaleStart + randomFloatFromInterval(0.25, 0.5),
              duration,
              id: node.emote.id,
              //channelID: node.channelID,
            });
            setTimeout(() => {
              const index = emotes.value.findIndex((e) => e.id === id);
              if (index !== -1) {
                emotes.value.splice(index, 1);
              }
            }, duration * 1.5);
          }
        });
      }
    }
    onMounted(() => {
      window.addEventListener(CUSTOM_EVENTS.CHAT_CREATED_EVENT, onChatMessage);
    });
    onBeforeUnmount(() => {
      window.removeEventListener(
        CUSTOM_EVENTS.CHAT_CREATED_EVENT,
        onChatMessage,
      );
    });

    return {
      emotes,
      emoteWall,
      containerStyle,
      opacity,
    };
  },
};
</script>

<style scoped lang="scss">
.emoji {
  display: inline-block;
  height: 2em;
  width: 2em;
  animation: emoteWallAnimation var(--duration) both;
  position: absolute;

  img {
    font-family:
      "Noto Color Emoji",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Android Emoji",
      EmojiSymbols,
      EmojiOne Mozilla,
      Twemoji Mozilla,
      Segoe UI Symbol,
      "Noto Color Emoji Compat",
      emoji,
      Ellipsis,
      ShoraiSans,
      ShoraiSans Fallback,
      system-ui,
      sans-serif;
    display: block;
    height: 2em;
    width: 2em;
    transform-origin: center center;
    object-fit: contain;
  }
}
</style>

<style>
@keyframes emoteWallAnimation {
  0% {
    top: var(--yStart);
    left: var(--xStart);
    transform: translate(-50%, -50%) rotate(var(--rotationStart))
      scale(var(--scaleStart));
    opacity: 0;
  }
  0% {
    opacity: calc(var(--opacityRatio * 0.1));
  }
  85% {
    opacity: var(--opacityRatio);
  }
  100% {
    opacity: 0;
    top: var(--yEnd);
    left: var(--xEnd);
    transform: translate(-50%, -50%) rotate(var(--rotationEnd))
      scale(var(--scaleEnd));
  }
}
</style>
