<template>
  <div ref="confetti"></div>
</template>

<script>
const maxParticleCount = 50; //set max confetti count
const particleSpeed = 0.35; //set the particle animation speed
export default {
  name: "Confetti",
  data() {
    return {
      colors: [
        "DodgerBlue",
        "OliveDrab",
        "Gold",
        "Pink",
        "SlateBlue",
        "LightBlue",
        "Violet",
        "PaleGreen",
        "SteelBlue",
        "SandyBrown",
        "Chocolate",
        "Crimson",
      ],
      streamingConfetti: false,
      animationTimer: null,
      particles: [],
      waveAngle: 0,
    };
  },
  methods: {
    startConfettiInner() {
      const width = this.$refs.confetti.clientWidth;
      const height = this.$refs.confetti.clientHeight;
      window.requestAnimFrame = (function () {
        return (
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          function (callback) {
            return setTimeout(callback, 16.6666667);
          }
        );
      })();
      const canvas = document.createElement("canvas");
      canvas.setAttribute("id", "confetti-canvas");
      canvas.setAttribute(
        "style",
        "display:block;z-index:999999;pointer-events:none; height: 100%; width: 100%"
      );
      this.$refs.confetti.appendChild(canvas);
      canvas.width = width;
      canvas.height = height;
      window.addEventListener(
        "resize",
        () => {
          canvas.width = this.$refs.confetti.clientWidth;
          canvas.height = this.$refs.confetti.clientHeight;
        },
        true
      );
      const context = canvas.getContext("2d");
      while (this.particles.length < maxParticleCount) {
        this.particles.push(this.resetParticle({}, width, height));
      }
      this.streamingConfetti = true;
      if (this.animationTimer === null) {
        const runAnimation = () => {
          if (this.$refs.confetti) {
            context.clearRect(
              0,
              0,
              this.$refs.confetti.clientWidth,
              this.$refs.confetti.clientHeight
            );
            if (this.particles.length === 0) this.animationTimer = null;
            else {
              this.updateParticles();
              this.drawParticles(context);
              this.animationTimer = requestAnimFrame(runAnimation);
            }
          }
        };
        runAnimation();
      }
    },
    resetParticle(particle, width, height) {
      particle.color = this.colors[(Math.random() * this.colors.length) | 0];
      particle.x = Math.random() * width;
      particle.y = Math.random() * height - height;
      particle.diameter = Math.random() * 10 + 5;
      particle.tilt = Math.random() * 10 - 10;
      particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
      particle.tiltAngle = 0;
      return particle;
    },

    drawParticles(context) {
      let particle;
      let x;
      for (let i = 0; i < this.particles.length; i++) {
        particle = this.particles[i];
        context.beginPath();
        context.lineWidth = particle.diameter;
        context.strokeStyle = particle.color;
        x = particle.x + particle.tilt;
        context.moveTo(x + particle.diameter / 2, particle.y);
        context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
        context.stroke();
      }
    },

    updateParticles() {
      const width = this.$refs.confetti.clientWidth;
      const height = this.$refs.confetti.clientHeight;
      let particle;
      this.waveAngle += 0.01;
      for (let i = 0; i < this.particles.length; i++) {
        particle = this.particles[i];
        if (!this.streamingConfetti && particle.y < -15)
          particle.y = height + 100;
        else {
          particle.tiltAngle += particle.tiltAngleIncrement;
          particle.x += Math.sin(this.waveAngle);
          particle.y +=
            (Math.cos(this.waveAngle) + particle.diameter) *
            particleSpeed *
            0.5;
          particle.tilt = Math.sin(particle.tiltAngle) * 15;
        }
        if (
          particle.x > width + 20 ||
          particle.x < -20 ||
          particle.y > height
        ) {
          if (
            this.streamingConfetti &&
            this.particles.length <= maxParticleCount
          )
            this.resetParticle(particle, width, height);
          else {
            this.particles.splice(i, 1);
            i--;
          }
        }
      }
    },
  },
  mounted() {
    this.startConfettiInner();
  },
};
</script>

<style scoped></style>
