<template>
  <div :style="textStyle">
    {{ text }}
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, ref, computed, watch } from "vue";
import WebFont from "webfontloader";
import { CUSTOM_EVENTS } from "../../../api/events.js";

export default {
  name: "FollowerCountWidget",
  props: {
    settings: {
      required: true,
    },
    user: {
      required: true,
    },
    cache: {
      type: Object,
      required: false,
    },
    fetchTwitchFollowerCount: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const followerCount = ref(props.cache?.followers || 0);
    let fetchInterval;

    function onChatMessage(event) {
      if (event.detail.type !== "new_follower") {
        return;
      }
      followerCount.value += 1;
    }

    onBeforeMount(() => {
      window.addEventListener(
        CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT,
        onChatMessage
      );
      WebFont.load({
        google: {
          families: [props.settings.fontFamily || "Inter"],
        },
      });
      // fetch follower count every 5 minutes to keep it up to date if we miss an event
      fetchInterval = setInterval(() => {
        props.fetchTwitchFollowerCount().then((res) => {
          if (res >= 0) {
            followerCount.value = res;
          }
        });
      }, 5 * 60 * 1000);
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT,
        onChatMessage
      );
      clearInterval(fetchInterval);
    });

    watch(
      () => props.settings.fontFamily + props.settings.fontStyle,
      () => {
        let fontName = props.settings.fontFamily || "Inter";

        WebFont.load({
          google: {
            families: [fontName + ":400,700,400italic,700italic"],
          },
        });
      }
    );

    onBeforeUnmount(() => {
      clearInterval(fetchInterval);
    });

    const template = computed(() => {
      return props.settings.template || "%count%";
    });

    const text = computed(() => {
      return template.value.replaceAll("%count%", followerCount.value);
    });

    const textStyle = computed(() => {
      return {
        fontSize: (props.settings.fontSize || 32) + "px",
        color: props.settings.fontColor || "black",
        fontFamily:
          (props.settings.fontFamily || "Inter") +
          ', "Helvetica Neue", Arial, sans-serif',
        fontWeight: props.settings.fontStyle.includes("bold")
          ? "bold"
          : "normal",
        fontStyle: props.settings.fontStyle.includes("italic")
          ? "italic"
          : "normal",
        textDecoration: props.settings.fontStyle.includes("underline")
          ? "underline"
          : "none",
      };
    });

    return {
      text,
      textStyle,
    };
  },
};
</script>

<style scoped></style>
