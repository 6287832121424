<template>
  <span>{{ current }}</span>
</template>

<script>
export default {
  name: "AnimatedNumber",
  data() {
    return {
      updateInterval: 0,
      current: this.value || 0,
    };
  },
  props: {
    value: {},
    animationDuration: {
      default: 500,
    },
  },
  watch: {
    value(value, oldValue) {
      if (oldValue > value) {
        this.current = 0;
      }
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
      }

      if (this.current !== value) {
        this.updateInterval = setInterval(() => {
          this.current = this.current + 1;
          if (this.current === value) {
            clearInterval(this.updateInterval);
          }
        }, this.animationDuration / Math.abs(value - this.current));
      }
    },
  },
  beforeUnmount() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  },
};
</script>

<style scoped></style>
