const cheerEmotes = [
  {
    prefix: "Cheer",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 1,
    last_updated: "2018-05-22T00:06:04Z",
    is_charitable: false,
  },
  {
    prefix: "DoodleCheer",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/doodlecheer/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
    ],
    type: "global_third_party",
    order: 1,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "BibleThump",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/biblethump/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 7,
    last_updated: "2020-10-20T00:10:40Z",
    is_charitable: false,
  },
  {
    prefix: "cheerwhal",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/cheerwhal/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 8,
    last_updated: "2019-11-26T22:19:20Z",
    is_charitable: false,
  },
  {
    prefix: "Corgo",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/corgo/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 9,
    last_updated: "2019-11-01T21:09:17Z",
    is_charitable: false,
  },
  {
    prefix: "Scoops",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/100/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/1000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/5000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/scoops/light/static/10000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
    ],
    type: "display_only",
    order: 10,
    last_updated: "2019-08-20T21:24:18Z",
    is_charitable: false,
  },
  {
    prefix: "uni",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/uni/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 11,
    last_updated: "2019-06-25T21:34:59Z",
    is_charitable: false,
  },
  {
    prefix: "ShowLove",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/showlove/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 12,
    last_updated: "2019-02-11T18:24:41Z",
    is_charitable: false,
  },
  {
    prefix: "Party",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/party/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 13,
    last_updated: "2018-05-22T00:06:04Z",
    is_charitable: false,
  },
  {
    prefix: "SeemsGood",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/seemsgood/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 14,
    last_updated: "2018-05-22T00:06:04Z",
    is_charitable: false,
  },
  {
    prefix: "Pride",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pride/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 15,
    last_updated: "2018-05-31T20:18:40Z",
    is_charitable: false,
  },
  {
    prefix: "Kappa",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kappa/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 16,
    last_updated: "2018-05-22T00:06:04Z",
    is_charitable: false,
  },
  {
    prefix: "FrankerZ",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/frankerz/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 17,
    last_updated: "2018-05-22T00:06:04Z",
    is_charitable: false,
  },
  {
    prefix: "HeyGuys",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/heyguys/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 18,
    last_updated: "2018-05-22T00:06:04Z",
    is_charitable: false,
  },
  {
    prefix: "DansGame",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/dansgame/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 19,
    last_updated: "2018-05-22T00:06:04Z",
    is_charitable: false,
  },
  {
    prefix: "EleGiggle",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/elegiggle/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 20,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "TriHard",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/trihard/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 21,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "Kreygasm",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/kreygasm/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 22,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "4Head",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/4head/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 23,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "SwiftRage",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/swiftrage/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 24,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "NotLikeThis",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/notlikethis/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 25,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "FailFish",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/failfish/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 26,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "VoHiYo",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/vohiyo/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 27,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "PJSalt",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/pjsalt/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 28,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "MrDestructoid",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/mrdestructoid/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 29,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "bday",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bday/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 30,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "RIPCheer",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/ripcheer/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 31,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "Shamrock",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/shamrock/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: true,
      },
    ],
    type: "global_first_party",
    order: 32,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "BitBoss",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/bitboss/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
    ],
    type: "global_third_party",
    order: 34,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "Streamlabs",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/streamlabs/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
    ],
    type: "global_third_party",
    order: 35,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "Muxy",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/muxy/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
    ],
    type: "global_third_party",
    order: 36,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "HolidayCheer",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/holidaycheer/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
    ],
    type: "global_third_party",
    order: 37,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "Goal",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/100/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/1000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/5000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/goal/light/static/10000/4.png",
            },
          },
        },
        can_cheer: true,
        show_in_bits_card: false,
      },
    ],
    type: "global_third_party",
    order: 38,
    last_updated: "2018-05-31T20:18:40Z",
    is_charitable: false,
  },
  {
    prefix: "Anon",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/100/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/1000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/5000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/anon/light/static/10000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
    ],
    type: "display_only",
    order: 39,
    last_updated: "2018-05-22T00:06:05Z",
    is_charitable: false,
  },
  {
    prefix: "Charity",
    tiers: [
      {
        min_bits: 1,
        id: "1",
        color: "#979797",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 100,
        id: "100",
        color: "#9c3ee8",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/100/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/100/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/100/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/100/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/100/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/100/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/100/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/100/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/100/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/100/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/100/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 1000,
        id: "1000",
        color: "#1db2a5",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/1000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/1000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/1000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 5000,
        id: "5000",
        color: "#0099fe",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/5000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/5000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/5000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/5000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/5000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/5000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/5000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/5000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/5000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/5000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/5000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
      {
        min_bits: 10000,
        id: "10000",
        color: "#f43021",
        images: {
          dark: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/dark/static/10000/4.png",
            },
          },
          light: {
            animated: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/10000/1.gif",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/10000/1.5.gif",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/10000/2.gif",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/10000/3.gif",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/animated/10000/4.gif",
            },
            static: {
              1: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/10000/1.png",
              1.5: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/10000/1.5.png",
              2: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/10000/2.png",
              3: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/10000/3.png",
              4: "https://d3aqoihi2n8ty8.cloudfront.net/actions/charity/light/static/10000/4.png",
            },
          },
        },
        can_cheer: false,
        show_in_bits_card: false,
      },
    ],
    type: "display_only",
    order: 2147483647,
    last_updated: "2018-08-31T22:36:22Z",
    is_charitable: true,
  },
];
export function getEmoteObject(prefix, amount) {
  const cheerEmote = cheerEmotes.find((cheer) => cheer.prefix === prefix);
  if (!cheerEmote) return null;
  let result = cheerEmote.tiers[0];
  // find tier by amount, amount can be in between 2 tiers
  cheerEmote.tiers.forEach((tier, index) => {
    if (amount >= tier.min_bits) {
      result = tier;
    }
  });
  return result;
}
export default cheerEmotes;
